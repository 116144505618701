import React from 'react';
import PropTypes from 'prop-types';
import HTMLParser from 'html-react-parser';

import { formColor } from '../../../../helper';

import styles from './styles.module.css';

class FAQLayout extends React.Component {

  constructor(props) {

    super(props);

    const index = props.section.data.length - 1;
    const align = props.section.data[index].align !== undefined ? props.section.data[index].align : 'Left';

    this.state = {
      section: props.section,
      align,
      openAccordion: null,
      layout: props.layout,
    };

    this.handleArrowClick = this.handleArrowClick.bind(this);
    this.createFAQS = this.createFAQS.bind(this);

  }

  handleArrowClick(event) {

    const openAccordion = Number(event.target.dataset.index) === this.state.openAccordion
      ? null : Number(event.target.dataset.index);
    this.setState({
      openAccordion,
    });

  }

  createFAQS() {

    const activeComponents = [];
    this.state.section.data.map((item, index) => {

      if (item.type === 'COMPONENT' && item.active) {

        activeComponents.push({ item, index });

      }
      return null;

    });

    let componentsWrapper = styles.FAQComponentsWrapper;
    if (this.state.layout === 'clean') {

      componentsWrapper = styles.FAQComponentsWrapperClean;

    }

    const faqs = [];
    activeComponents.forEach((item, index) => {

      let style;
      if (item.item.styles.backgroundColor.active) {

        let { backgroundColor } = item.item.styles;
        if (
          item.item.styles.backgroundColor.solid === ''
          && item.item.styles.backgroundColor.gradient.from === ''
        ) {

          backgroundColor = { solid: '#ffffff' };

        }

        style = formColor(
          backgroundColor,
          false,
          item.item.styles.backgroundColor.opacity,
        );

      }

      let tempArrow = 'accordion_arrow';
      let itemStyle;

      if (this.state.layout === 'clean') {

        itemStyle = 'accordion__item';
        if (
          (this.state.openAccordion !== null && index === this.state.openAccordion)
          || !item.item.data[0].active
        ) {

          tempArrow = 'accordion_arrow_open';

        }

      } else {

        if (
          (this.state.openAccordion !== null && index === this.state.openAccordion)
          || !item.item.data[0].active
        ) {

          tempArrow = 'accordion_arrow_2_open';

        } else {

          tempArrow = 'accordion_arrow_2';

        }

      }

      const hidden = (
        this.state.openAccordion === null
        || index !== this.state.openAccordion
        || !item.item.data[0].active
      );

      const textStyle = !hidden ? {} : { display: 'none' };
      const text = item.item.data[1].active
        ? (
          <div
            className={`${styles.FAQComponentParagraph} ${styles[`align${item.item.align}`]}`}
            style={textStyle}
          >
            { HTMLParser(`<span>${item.item.data[1].text}</span>`) }
          </div>
        ) : undefined;

      const faq = (
        <div
          key={`FAQLayout_Section_${this.state.section._id}_${index}`}
          style={style}
          className={componentsWrapper}
        >
          {(this.state.layout === 'clean' && index > 0 && <hr className={styles.FAQDivider} />)}
          <div>
            <div>
              <div
                data-index={index}
                onClick={this.handleArrowClick}
                className={styles[itemStyle]}
              >
                {
                  item.item.data[0].active
                    ? (
                      <div
                        data-index={index}
                        onClick={this.handleArrowClick}
                      >
                        <div
                          className={styles[tempArrow]}
                          data-index={index}
                          onClick={this.handleArrowClick}
                        />
                        <div
                          className={`${styles[`align${item.item.align}`]}`}
                          data-index={index}
                          onClick={this.handleArrowClick}
                        >
                          <div
                            className={styles.FAQComponentHeader}
                            data-index={index}
                            onClick={this.handleArrowClick}
                          >
                            <h5
                              data-index={index}
                              onClick={this.handleArrowClick}
                            >
                              {HTMLParser(`${item.item.data[0].text}`)}
                            </h5>
                          </div>
                        </div>
                      </div>
                    )
                    : (
                      <div className={styles.positioner} />
                    )
                }
                { text }
              </div>
            </div>
          </div>
        </div>
      );

      faqs.push(faq);

    });

    return faqs;

  }

  render() {

    const alignStyle = `align${this.state.align}`;
    const faqs = this.createFAQS();

    return (
      <div className="container">
        <div className="row">
          <div className={`col-12 col-sm-10 offset-sm-1 col-xl-8 offset-xl-2 ${styles[alignStyle]}`}>
            {
              this.state.section.data.map((elem, i) => {

                let result;
                if (elem.active && elem.type === 'HEADINGS/HEADING-TWO') {

                  result = (
                    <div
                      key={`${this.state.section._id}_data_${i}`}
                      className={styles.FAQMainHeader}
                    >
                      { HTMLParser(`<h2>${elem.text}</h2>`) }
                    </div>
                  );

                } else if (elem.active && elem.type === 'PARAGRAPH/PARAGRAPH') {

                  result = (
                    <div key={`${this.state.section._id}_data_${i}`}>
                      { HTMLParser(`<span>${elem.text}</span>`) }
                    </div>
                  );

                }

                return result;

              })
            }
            <div className={styles.accordionWrapper}>
              { faqs }
            </div>
          </div>
        </div>
      </div>
    );

  }

}

FAQLayout.propTypes = {
  section: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.shape({
      align: PropTypes.string,
    })),
  }),
};

export default FAQLayout;
